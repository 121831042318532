import React from 'react';
import ClientApp from '../../src/ClientApp';
import ShopRoutes from '../../src/routes/ShopRoutes';

const ShopRoutesClient = () => (
  <ClientApp>
    <ShopRoutes />
  </ClientApp>
);

export default ShopRoutesClient;

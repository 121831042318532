import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserContext } from '../../actions/UserActions';

/**
 * Helper component that blocks rendering of a routed component until the UserContext is loaded
 * If the auth flag is turned on, any user don't have registry will be redirect to the login page
 * @param auth {string} should check has_registry flag
 * @param hasRegistry {string} should have registry account
 * @param RoutedComponent {JSX} actual component that should be render
 * @param rest {Object} all the props that should be passed to RoutedComponent
 * @returns {*}
 * @constructor
 */
interface UserContextRouteProps extends RouteProps {
  auth?: boolean;
  hasRegistry?: boolean;
}

const UserContextRoute = ({
  auth,
  component: RoutedComponent,
  hasRegistry,
  ...rest
}: UserContextRouteProps) => {
  const dispatch = useDispatch();
  const userContext = useSelector((state) => state.user.userContext);
  useEffect(() => {
    dispatch(getUserContext());
  }, []);

  if (!RoutedComponent) {
    return null;
  }

  // route guard
  if (auth && userContext) {
    // Return user to the same path if auth is required
    const returnToPath = encodeURIComponent(window.location.pathname + window.location.search);

    if (hasRegistry && !userContext.has_registry) {
      window.location.href = `/account/login?returnTo=${returnToPath}`;
      return null;
    }

    if (userContext.is_guest) {
      window.location.href = `/account/login?returnTo=${returnToPath}`;
      return null;
    }
  }

  return <Route {...rest} render={(props) => userContext && <RoutedComponent {...props} />} />;
};

export default UserContextRoute;
